import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { Accordion, Spinner } from 'react-bootstrap';
import SoundCloudPlayer from "./soundcloud/soundCloudPlayer";
import Background from "../../shared/components/background/background"
import Header from "../../shared/components/header/header"
import Footer from "../../shared/components/footer/footer"
import Stripe from "../../shared/components/stripe/stripeContainer"
import { GetPack } from '../../api/API';
import "../../shared/styles/common.css";
import "./packDetails.css"

export default function PackDetails() {
  const init = {
    title: "Loading",
    description: [],
    specs: [],
    images: []
  }
  const [pack, setPack] = useState(init);
  const [loading, setLoading] = useState(true);
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    getProductDetails();
  }, []);

  async function getProductDetails() {
    //local 
    //let stripeData = localData;
    //prod
    let stripeData = await GetPack(id);
    if (stripeData.length === 0) {
      navigate("../pack-not-found", { replace: true });
    }
    setPack(stripeData);
    setLoading(false);
  }

  return (
    <div className="page-wrapper contact-background background-wrap">
      <Background />
      <Header />
      <div className="page-content background-content music-wrapper">
        {loading ? <p> <Spinner className="general-spinner" animation="border" /> </p> : <>
          <div>
            <div className="grid-container-pack-desktop">
              <h2 className="general-title pack-name">
                {pack.name}
              </h2>
            </div>
            <div className="grid-container-pack-desktop">
              <img className="pack-image grid-item-pack-desclassktop" src={pack.images[0]} />
              <div className="grid-item-pack-desktop">
                <div className="pack-description">
                  {pack.description.map((para) => (
                    <p key={para}>
                      {para}
                    </p>
                  ))}
                </div>
                <h3 className="pack-price" >
                  ${pack.price} USD
                </h3>
              </div>
            </div>
          </div>
          <div>
            <div className="pack-details">
              <SoundCloudPlayer srcId={pack.srcId} />
              <Accordion flush alwaysOpen variant="dark">
                <Accordion.Item eventKey="0" className="pack-specs">
                  <Accordion.Header className="specs-subtitle">SPECIFICATIONS</Accordion.Header>
                  <Accordion.Body>
                    {pack.specs.map((packDetails) => (
                      <p className='pack-dropdown' key={packDetails}>
                        {packDetails}
                      </p>
                    ))}
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
            <Stripe priceId={pack.default_price} />
          </div>
        </>}
      </div>
      <Footer />
    </div>
  );
}

const localData = {
  "id": "prod_Lj8IvMc2AfQPpp",
  "price": 35,
  "srcId": 1236051643,
  "object": "product",
  "active": true,
  "attributes": [],
  "created": 1653092335,
  "default_price": "price_1L1g1zEqop9ulKo97o8xn6cx",
  "description": ["We’re loving the progression that Electronic Dance has been making over the past few years. Certain genres are really taking hold and showcasing their own eclectic style. \n\n This delectable Zenhiser sound pack sits on the fence, shining bright over those majestic genres, Melodic House & Techno and Melodic Techno.  Packed with tight rhythms, quirky synths, grumbling basslines, dynamic fx and a heap of one shots, midi, drum sounds and more, ‘Fur - Melodic Techno’ presents itself with no fluff or false bravado, just 2.6GB of rock solid content. \n\n It’s an essential addition for any House or Techno producer whose looking to bypass the mainstream sound. This alternative direction gives the collection its foundation. ‘Fur - Melodic House & Techno’ was crafted by pulling out the classic hardware and experimenting with alternative and new processing techniques. That gamble created an authentic Melodic House & Melodic Techno pack that captures the raw energy of what these fascinating genres truly offer.  Take the pack for a spin, we’re sure you’ll enjoy the ride!"],
  "images": [
    "https://files.stripe.com/links/MDB8YWNjdF8xS244YWFFcW9wOXVsS285fGZsX3Rlc3RfTHpHdEFQdGpJQUpsSUdUS2N1NU5wSGYy00hqyHfW0U"
  ],
  "livemode": false,
  "metadata": {
    "idUrl": "longer-test-pack"
  },
  "name": "Longer Test Pack",
  "package_dimensions": null,
  "shippable": null,
  "statement_descriptor": null,
  "tax_code": null,
  "type": "service",
  "unit_label": null,
  "updated": 1653094375,
  "url": null,
  "specs": [
    "Drum Hits - 01 Kick - 25",
    "Drum Hits - 02 Clap - 13",
    "Drum Hits - 03 Snare - 12",
    "Drum Hits - 04 Hi Hat - 25",
    "Loops - Arp - 26",
    "Loops - Bassline - 81",
    "Loops - Drum Beat - 150",
    "Loops - Full Mix - 150",
    "Loops - Midi - 162",
    "Loops - Synth & Instrument - 166",
    "Loops - Vocal - 52",
    "One Shots - Bass Hit - 25",
    "One Shots - FX - 49",
    "One Shots - Synth Hit - 25",
    "Total Samples - 804",
    "Total Midi - 162",
    "Total Presets - 0",
    "Tempo - 124bpm - 128bpm",
    "Key Info - Yes",
    "Audio - 24-Bit Wav",
    "Unzip Size - 1.8GB",
    "Zip Size - 2.0GB",
  ]
}