import axios from "axios";
import * as urls from "./constants/urls";

const enviroment = process.env.REACT_APP_ENVIROMENT;

export async function GetCheckout(priceId) {
    const checkoutUrl = urls.checkout;
    const body = {
        id: priceId,
        success: "https://fovosmusic.com/success",
        failure: "https://fovosmusic.com/failure",
        enviroment: process.env.REACT_APP_ENVIROMENT
    };

    try {
        const response = await axios.post(checkoutUrl, body);
        if (response.data) {
            location.href = response.data.url;
        }
    } catch (error) {
        console.log("ERROR POSTING TO - " + checkoutUrl);
    }
}

export async function GetPack(id) {
    let stripeData = [];
    const productUrl = urls.packs + `?id=${id}`;
    const body = {
        enviroment: process.env.REACT_APP_ENVIROMENT
    };
    await axios.post(productUrl, body).then((response) => {
        if (!response.data || !response.data.value === null) {
            return;
        }
        let productData = response.data;
        if (typeof productData.stripe !== 'undefined' &&
            typeof productData.firestore !== 'undefined') {
            stripeData = productData.stripe.data[0].product;
            const firestoreData = productData.firestore;
            stripeData.price = (productData.stripe.data[0].unit_amount / 100).toFixed(2);
            stripeData.srcId = firestoreData.srcId.stringValue;
            stripeData.description = firestoreData.description.arrayValue.values.map(function (item) {
                return item['stringValue'];
            });
            stripeData.specs = firestoreData.specifications.arrayValue.values.map(function (item) {
                return item['stringValue'];
            });
        }
    });
    return stripeData;
}

export async function GetPacks() {
    const productUrl = urls.packs;
    const body = {
        enviroment: process.env.REACT_APP_ENVIROMENT
    };
    console.log(productUrl, body)

    let packs = [];
    await axios.post(productUrl, body).then((response) => {
        packs = response.data.data;
    });
    console.log(packs);
    let productList = [];
    packs.forEach((data, i) => {
        let urlId = data.product.name.toLowerCase();
        urlId = urlId.replace(/ /g, "-");
        let product = {
            priceId: data.id,
            urlId: urlId,
            name: data.product.name.toUpperCase(),
            image: data.product.images[0],
            description: data.product.description,
            price: (data.unit_amount / 100).toFixed(2),
            currency: data.currency
        };
        productList.push(product);
    });
    return productList;
}