import * as React from 'react';
import "./comingSoon.css"

export default function comingSoon(props) {
  return (
    <div className="coming-soon-wrapper">
      <div className="coming-soon-title">
        {props.title}
      </div>
      <div className="coming-soon-text">
        More stuff coming soon. 
        Stay tuned.
      </div>
    </div>
  );
}