import React, { useEffect, useState } from 'react';

export default function Player(props) {
  const [id, setId] = useState("");
  useEffect(() => {
    let id =
      `https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/${props.srcId}&color=%233d3532&inverse=true&auto_play=false&show_user=true`;
    setId(id);

    console.log(id);
  }, []);

  return (
    <iframe style={{ marginBottom: "30px", marginBottom: "30px" }} width="100%" height="20" scrolling="no" frameBorder="no" allow="autoplay"
      src={id}>
    </iframe>
  );
};