import React, { useState, useEffect } from 'react';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import { Link } from "react-router-dom";
import {FaInstagram, FaFacebook, FaTiktok, FaYoutube, FaSpotify, FaApple } from 'react-icons/fa';
import logo from'../../images/fovos-logo.png';
import 'bootstrap/dist/css/bootstrap.min.css';
import './header.css';

export default function Header () {
  const [isDesktop, setDesktop] = useState(window.innerWidth > 765);

  const updateMedia = () => {
    setDesktop(window.innerWidth > 765);
  };

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });

    return (
      <Navbar className="header-wrapper" collapseOnSelect expand="md" bg="transparent" variant="dark">
        <Navbar.Brand className="pr-10" href="/">
          <img src={logo} alt="Girl in a jacket" height="30"/>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto">
              <Link className="header-button topper-button" to="/music" >MUSIC</Link>
              <Link className="header-button" to="/playlist">PLAYLIST</Link>
              <Link className="px-2 header-button" to="/packs">PACKS</Link>
              <Link className="header-button" to="/contact">CONTACT</Link>
          </Nav>
          {isDesktop ? (
            <Nav>
            <Nav.Link 
              href="https://www.instagram.com/fovosmusic/">
              <FaInstagram className="header-social-icon"/>
            </Nav.Link>
            <Nav.Link 
              href="https://m.facebook.com/fovosmusic">
              <FaFacebook className="header-social-icon"/>
            </Nav.Link>
            <Nav.Link 
              href="https://www.tiktok.com/@fovosmusic">
              <FaTiktok className="header-social-icon"/>
            </Nav.Link>
            <Nav.Link 
              href="https://www.youtube.com/c/FOVOSmusic">
              <FaYoutube className="header-social-icon"/>
            </Nav.Link>
            <Nav.Link 
              href="https://open.spotify.com/artist/1ccmwHm4DPRPR12TZVWdu8">
              <FaSpotify className="header-social-icon"/>
            </Nav.Link>
            <Nav.Link 
              href="https://music.apple.com/us/artist/fovos/1534598362">
              <FaApple className="header-social-icon"/>
            </Nav.Link>
          </Nav>
          ) : (
            <></>
          )}
        </Navbar.Collapse>
      </Navbar>
    );
}