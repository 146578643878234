import Header from "../../shared/components/header/header"
import Footer from "../../shared/components/footer/footer"
import Background from "../../shared/components/background/background"
import Spotify from "react-spotify-embed";
import "../../shared/styles/common.css"
import "./music.css"

export default function Music() {
    return (
      <div className="page-wrapper contact-background background-wrap">
        <Background/>
        <Header/>
        <div className="page-content background-content music-wrapper">
          <h2 className="general-title">
            OUR LATEST TUNES
          </h2>
            <div className="music-spotify-wrapper" >
              <Spotify className="music-spotify" 
              link="https://open.spotify.com/playlist/1ixUxM3eXuTWBDgyS7dGPX?si=b08583f960ed40a1"/>
            </div>
        </div>
        <Footer/>
      </div>
    );
}