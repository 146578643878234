import Header from "../../shared/components/header/header"
import Footer from "../../shared/components/footer/footer"
import Background from "../../shared/components/background/background"
import "../../shared/styles/common.css"
import "./notFound.css"

export default function NotFound() {
    return (
      <div className="page-wrapper contact-background background-wrap">
        <Background/>
        <Header/>
        <div className="page-content background-content general-wrapper notFound-wrapper">
          <h2 className="general-title">
            LOOKS LIKE YOU'RE LOST...
          </h2>
        </div>
        <Footer/>
      </div>
    );
}