import Header from "../../shared/components/header/header"
import Footer from "../../shared/components/footer/footer"
import Background from "../../shared/components/background/background"
import Comingsoon from "../../shared/components/comingSoon/comingSoon"
import PackList from "./packList"
import React, { useEffect, useState } from 'react';
import { Spinner } from "react-bootstrap"
import { GetPacks } from "../../api/API";
import "../../shared/styles/common.css"

export default function Packs() {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [noPacks, setNoPacks] = useState(false);

  useEffect(() => {
    getProducts();
  }, []);

  async function getProducts() {
    //prod
    let productData = await GetPacks();
    //local
    //let productData = productDataLocal;
    console.log(productData);
    setProducts(productData);
    setLoading(false);
    if (productData.length === 0) {
      console.log("set not packs to true")
      setNoPacks(true);
    }
  }

  return (
    <div className="page-wrapper contact-background background-wrap">
      <Background />
      <Header />
      <div className="page-content background-content music-wrapper">
        <h2 className="general-title">
          SOUND PACKS
        </h2>
        {loading ? <p> <Spinner className="general-spinner" animation="border" /> </p> : <>
          <div className="page-content background-content">
            <PackList key={products} products={products} />
          </div>
        </>}
        {noPacks && !loading ? <Comingsoon /> : <></>}
      </div>
      <Footer />
    </div>
  );
}

const productDataLocal = [
  {
    currency: "usd",
    description: "Just a pack that will help with testing. Not sure how long this description will be.",
    image: "https://files.stripe.com/links/MDB8YWNjdF8xS244YWFFcW9wOXVsS285fGZsX3Rlc3RfQTd0cmViR1dmQ0pBMlBpRFF4empBOW5h00FIo9H5pu",
    name: "Test Pack",
    price: 2500,
    priceId: "price_1Ks0W4Eqop9ulKo91AzCREJq",
    urlId: "test-pack",
  },
  {
    currency: "usd",
    description: "Just a pack that will help with testing. Not sure how long this description will be.",
    image: "https://files.stripe.com/links/MDB8YWNjdF8xS244YWFFcW9wOXVsS285fGZsX3Rlc3RfQTd0cmViR1dmQ0pBMlBpRFF4empBOW5h00FIo9H5pu",
    name: "A longer pack name",
    price: 2500,
    priceId: "price_1Ks0W4Eqop9ulKo91AzCREJq",
    urlId: "longer-test-pack",
  },
  {
    currency: "usd",
    description: "Just a pack that will help with testing. Not sure how long this description will be.",
    image: "https://files.stripe.com/links/MDB8YWNjdF8xS244YWFFcW9wOXVsS285fGZsX3Rlc3RfQTd0cmViR1dmQ0pBMlBpRFF4empBOW5h00FIo9H5pu",
    name: "PACK EXAMPLE",
    price: 2500,
    priceId: "price_1Ks0W4Eqop9ulKo91AzCREJq",
    urlId: "pack-example",
  }];