import Header from "../../shared/components/header/header"
import Footer from "../../shared/components/footer/footer"
import Background from "../../shared/components/background/background"
import { Col, Container, Row } from "react-bootstrap";
import "../../shared/styles/common.css"
import "./contact.css"

export default function Contact() {
    return (
      <div className="page-wrapper contact-background background-wrap">
        <Background/>
        <Header/>
        <div className="page-content background-content">
          <Container className="contact-wrapper">
            <Col>
              <Row>
              <Col className="contact-management">
                <h2 className="general-title">
                  MANAGEMENT
                </h2>
                <h5 className="contact-padding">
                  <a className="contact-info" target="_blank" href="mailto:robert@nightparrotagency.com">
                    Robert Flott | robert@nightparrotagency.com
                  </a>
                </h5>
                <h5 className="contact-padding">
                  <a className="contact-info" target="_blank" href="mailto:collin@nightparrotagency.com">
                    Collin Pin | collin@nightparrotagency.com
                  </a>
                </h5>
              </Col>
              <Col className="contact-bookings">
                <h2 className="general-title">
                  BOOKINGS
                </h2>
                <h5 className="contact-padding">
                  <a className="contact-info" target="_blank" href="mailto:fovosmusic@gmail.com">
                    Mario | fovosmusic@gmail.com
                  </a>
                </h5>
                <h5 className="contact-padding">
                  <a className="contact-info contact-rider" target="_blank" href="https://drive.google.com/drive/folders/17_5UxouSK5KYf26Yqtp88bpyJcg9HLt2?usp=sharing">
                   [ Download our EPK and rider ]
                  </a>
                </h5>
              </Col>
            </Row>
              <Row>
                <div className="contact-socials">
                  <h4 className="general-title insta-comment">
                    OR JUST DM US ON INSTAGRAM :)
                  </h4>
                </div>
              </Row>
            </Col>
          </Container>
        </div>
        <Footer/>
      </div>
    );
}