import Header from "../../shared/components/header/header"
import Footer from "../../shared/components/footer/footer"
import Background from "../../shared/components/background/background"
import Spotify from "react-spotify-embed";
import "../../shared/styles/common.css"
import "./playlist.css"

export default function Playlist() {
    return (
      <div className="page-wrapper contact-background background-wrap">
        <Background/>
        <Header/>
        <div className="page-content background-content playlist-wrapper">
          <h2 className="general-title">
            CHECK OUT OUR PLAYLIST
          </h2>
            <div className="playlist-spotify-wrapper" >
              <Spotify className="playlist-spotify" 
              link="https://open.spotify.com/playlist/6sG51BpNONALN7TE7OYuJW?si=6c3cb3e7b15f44ec"/>
            </div>
        </div>
        <Footer/>
      </div>
    );
}