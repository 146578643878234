import Card from "react-bootstrap/Card";
import React from 'react';
import { Link } from "react-router-dom";
import "../../shared/styles/common.css";
import "./packList.css"

export default function PackList(props) {
  return (
    <div className="grid-container">
      {props.products.map((product) => (
        <Link
          key={product.urlId}
          to={{
            pathname: `${product.urlId}`,
            query: `${product.urlId}`,
          }}>
          <Card className="grid-item pack-wrapper text-center" bg="light" border="light">
            <Card.Img className="pack-img" variant="top" src={product.image} />
            <Card.Header className="packlistitem-name">{product.name}</Card.Header>
            <Card.Body>
              <Card.Text className="packlistitem-price">
                ${product.price}
              </Card.Text>
            </Card.Body>
          </Card>
        </Link>
      ))}
    </div>
  );
}