import Header from "../../shared/components/header/header"
import Footer from "../../shared/components/footer/footer"
import Background from "../../shared/components/background/background"
import "../../shared/styles/common.css"

export default function Success() {
    return (
      <div className="page-wrapper contact-background background-wrap">
        <Background/>
        <Header/>
        <div className="page-content background-content playlist-wrapper">
          <h2 className="general-title">
            THANKS FOR YOUR PURCHASE!
          </h2>
          <h4 className="general-subtitle">
            Check your email for more details. If you do not receving an email within 24hours, please feel free to get in touch with us at fovosmusic@gmail.com.
          </h4>
        </div>
        <Footer/>
      </div>
    );
}