import { render } from "react-dom";
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import App from "./App";
import About from "./routes/about/about";
import Contact from "./routes/contact/contact";
import NotFound from "./routes/404/notFound";
import Packs from "./routes/packs/packs";
import PackDetails from "./routes/packs/packDetails";
import Playlist from "./routes/playlist/playlist";
import Music from "./routes/music/music";
import Failure from "./routes/failure/failure";
import Success from "./routes/success/success";
import "./index.css"

const rootElement = document.getElementById("root");
render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<App />} />
      <Route path="about" element={<About />} />
      <Route path="contact" element={<Contact />} />
      <Route path="*" element={<NotFound />} />
      <Route path="packs" element={<Packs />} />
      <Route path="packs/:id" element={<PackDetails />} />
      <Route path="playlist" element={<Playlist />} />
      <Route path="music" element={<Music />} />
      <Route path="success" element={<Success />} />
      <Route path="failure" element={<Failure />} />
    </Routes>
  </BrowserRouter>,
  rootElement
);