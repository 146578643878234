import Header from "../../shared/components/header/header"
import Footer from "../../shared/components/footer/footer"
import "../../shared/styles/common.css"

export default function About() {
    return (
      <div className="page-wrapper">
        <Header/>
        <div className="page-content">
          <ComingSoon title="About"/>
        </div>
        <Footer/>
      </div>
    );
}