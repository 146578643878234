import React, { useState } from "react";
import { Button, Spinner } from "react-bootstrap";
import { GetCheckout } from "../../../api/API";
import "./stripe.css"

export const CheckoutForm = (props) => {
    const [loading, setLoading] = useState(false);

    const handleSubmit = async () => {
        setLoading(true);
        await GetCheckout(props.priceId);
        setLoading(false);
    };

    return (
        <Button className="stripe-button" variant="light" onClick={handleSubmit}>
            {loading ?
                <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                />
                : "BUY NOW"}
        </Button>
    );
};