import Header from "../../shared/components/header/header"
import Footer from "../../shared/components/footer/footer"
import gif from "./fovos-pc.gif"
import "../../shared/styles/common.css"
import "./home.css"

export default function Home() {
    return (
      <div className="page-wrapper background-wrap">
            <img className="background-bg main-gif" src={gif}/>
        <Header/>
          <div className="page-content background-content">
            <div className="home-content">
            </div>
          </div>
        <Footer/>
      </div>
    );
}